









































































































import { Component, Vue } from 'vue-property-decorator';
import UserAccountModule from '@/store/modules/UserAccountModule';
import AuthModule from '@/store/modules/AuthModule';
import accounterrors from '@/pages/account/components/AccountErrors.vue';

@Component({
  components: {
    accounterrors,
  },
})
export default class ChangeUsernamePage extends Vue {
  newusername: string | null = null;
  showValidateMessage = false;

  get validateUsername() {
    if (this.newusername) {
      return (
        this.newusername.length >= 8 && this.newusername.length <= 100
      );
    } else if (this.showValidateMessage) {
      return false;
    } else {
      return null;
    }
  }

  async changeUsername() {
    if (this.validateUsername) {
      await UserAccountModule.changeUserName(this.newusername!);
      if (UserAccountModule.changeUsernameSucceeded) {
        this.newusername = null;
      }
    }
  }

  get changeUsernameError() {
    return UserAccountModule.changeUsernameError;
  }

  get changeUsernameProcessing() {
    return UserAccountModule.changeUsernameProcessing;
  }

  get changeUsernameSucceeded() {
    return UserAccountModule.changeUsernameSucceeded;
  }

  get currentUsername() {
    return AuthModule.profile?.UserName;
  }
}
